// import '/images/item_1.jpg' from '/images/item_1.jpg';

const bidsData = [
	{
		id: 1,
		image: './images/home/entwo1.png',
		title: 'Entwo',
		bid_number: 50,
		eth_number: 3,
		react_number: 159,
	},
	{
		id: 2,
		image: './images/home/Lorem.png',
		title: 'Lorem ',
		bid_number: 25,
		eth_number: 4,
		react_number: 150,
	},
	{
		id: 3,
		image: './images/home/bts1.png',
		title: 'PT Bumi Teknik Semesta',
		bid_number: 30,
		eth_number: 4,
		react_number: 100,
	},
	{
		id: 4,
		image: './images/home/nocola1.png',
		title: 'PT Nocola IoT Solution',
		bid_number: 20,
		eth_number: 4,
		react_number: 100,
	},
	// {
	// 	id: 5,
	// 	image: '/images/products/item_5.jpg',
	// 	title: 'ETH Me Outside',
	// 	bid_number: 15,
	// 	eth_number: 1,
	// 	react_number: 159,
	// },
	// {
	// 	id: 6,
	// 	image: '/images/products/item_6.jpg',
	// 	title: 'ETH Me Outside',
	// 	bid_number: 11,
	// 	eth_number: 6,
	// 	react_number: 159,
	// },
];

export { bidsData };
